// Com alteração no Layout, foi retirado o botão 'Resultado de Exames' e foi mantido o 'Agendar Exame'
import React, { useState } from 'react'
import styled from 'styled-components'

import { Toolbar, ToolbarItem } from 'reakit/Toolbar'
// import { Button } from 'reakit/Button'

import PurpleButtonStyled from './PurpleButton.styled'
// import BlueButtonStyled from './BlueButton.styled'
import { ifProp, theme } from 'styled-tools'
import ScheduleModal from '../ScheduleModal/scheduleModal'
import { selfServiceBrands } from './Whitebar'
import MySchedules from '../MySchedules/MySchedules'
import { Box } from '@material-ui/core'

const ButtonText = styled.span`
	margin: 0 auto;
	font-weight: 700 !important;
	font-size: 16px !important;
`

const StyledButton = styled(ToolbarItem)`
	background-color: #009fde;
	align-items: center;
	display: flex;
	margin: 0px 2px;
`

const StyledToolbar = styled(Toolbar)`
	&& {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		max-height: 100px !important;
		
		background-color: ${theme('colors.white')};
		padding: 10px;
        		
		border-bottom: ${ifProp('isFromMenu', 'none', `1px solid ${theme('colors.gray8')}`)};
		background-color: ${ifProp('isFromMenu', theme('colors.grayf8'), theme('colors.white'))};
		margin-bottom: ${ifProp('isFromMenu', '20px', '0')};
		
		
		/* border-bottom: ${props => (props.isFromMenu ? 'none' : '1px solid ' + props.theme.colors.gray8)}; */
		/* margin-bottom: ${props => (props.isFromMenu ? '20px' : '0')}; */
		/* background-color: ${props => (props.isFromMenu ? props.theme.colors.grayf8 : props.theme.colors.white)}; */

		/* margin: 0 auto;
    width: fit-content; */
	}
`

const LeftButton = styled.a`
	&& {
		${PurpleButtonStyled}
		//background-color: ${theme('colors.button_agendar')};
		background-color: #009FDE;
		//border-color: ${theme('colors.button_agendar')};
		border-color: #009FDE;
		width: 95%;
		padding: 10px;
		font-weight: 500;
		font-style: normal;
		font-stretch: normal;
		line-height: 1.36;
		text-decoration: none;
		text-align: center;

		&:not([disabled]):hover {
			background-color: ${theme('colors.button_agendar')};
			border-color: ${theme('colors.button_agendar')};
			opacity: 0.8;
		}

		&:not([disabled]):active {
			background-color: ${theme('colors.button_agendar')};
			border-color: ${theme('colors.button_agendar')};
		}
	}
`

// const RightButton = styled.a`
// 	${PurpleButtonStyled}
// 	width: 49%;
// 	padding: 10px;
// 	font-weight: 500;
// 	font-style: normal;
// 	font-stretch: normal;
// 	line-height: 1.36;
// 	text-decoration: none;
// 	font-size: 100%;
// `

const ButtonWrapper = styled.div`
	border-bottom: 1px solid ${theme('colors.gray8')};
	padding: 20px 0;
`

/**
 * Os botões de "Agendar Exame" e "Resultado de Exame para mobile".
 * É usado tanto na Navbar quanto no HomeOnly. Quando na navbar, a props isFromMenu é true.
 */
const MobileTwoButtons = ({ toolbar, isFromMenu, type, regionais, contatos, contatosAgendar }) => {
	const [showSchedule, setShowSchedule] = useState(false)
	return (
		<>
			<ScheduleModal
				showSchedule={showSchedule}
				setShowSchedule={setShowSchedule}
				contatos={contatos}
				contatosAgendar={contatosAgendar}
			></ScheduleModal>
			<StyledToolbar {...toolbar} as={ButtonWrapper} isFromMenu={isFromMenu}>
				{process.env.GATSBY_COSMIC_BUCKET === 'amaissp' ? (
					<StyledButton
						{...toolbar}
						as={LeftButton}
						onClick={() => window.open('https://agendamento.amaissaude.com.br/entrar', '_self')}
					>
						<ButtonText>Agendar exames e vacinas</ButtonText>
					</StyledButton>
				) : process.env.GATSBY_COSMIC_BUCKET === 'labs-amais' ? (
					<StyledButton
						{...toolbar}
						as={LeftButton}
						onClick={() => window.open('https://agendamento.labsamais.com.br/', '_self')}
					>
						<ButtonText>Agendar exames e vacinas</ButtonText>
					</StyledButton>
				) : (
					<StyledButton {...toolbar} as={LeftButton} onClick={() => setShowSchedule(true)}>
						<ButtonText>Agendar</ButtonText>
					</StyledButton>
				)}
				<Box display='flex' justifyContent='center' marginTop='5px'>
					{selfServiceBrands.includes(process.env.GATSBY_COSMIC_BUCKET) && <MySchedules />}
				</Box>
				{/* <StyledButton {...toolbar} as={RightButton} href={`https://resultados.amaissaude.com.br/${type}`} target='_blank'>
				<ButtonText>Resultado de Exames</ButtonText>
			</StyledButton> */}
			</StyledToolbar>
		</>
	)
}

export default MobileTwoButtons

import React from 'react'
// import theme from '../../theme'

const SvgPinSelect = props => (
	<svg width='15' height='16' viewBox='0 0 15 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			d='M7.5 8.00006C7.15868 8.00006 6.82502 7.90622 6.54123 7.73041C6.25743 7.5546 6.03623 7.30472 5.90562 7.01236C5.775 6.72 5.74082 6.3983 5.80741 6.08793C5.874 5.77756 6.03836 5.49247 6.27971 5.26871C6.52106 5.04495 6.82856 4.89256 7.16332 4.83083C7.49809 4.76909 7.84508 4.80078 8.16041 4.92188C8.47575 5.04297 8.74528 5.24805 8.93491 5.51117C9.12453 5.77428 9.22575 6.08362 9.22575 6.40007C9.22438 6.82403 9.04212 7.23025 8.71878 7.53004C8.39543 7.82982 7.95728 7.99879 7.5 8.00006ZM12.6772 6.56007C12.6772 5.28704 12.1318 4.06615 11.1609 3.16598C10.1899 2.26582 8.87309 1.76011 7.5 1.76011C6.12691 1.76011 4.81006 2.26582 3.83914 3.16598C2.86822 4.06615 2.32276 5.28704 2.32276 6.56007C2.32276 8.43206 4.00536 10.912 7.5 13.872C10.9946 10.912 12.6772 8.43206 12.6772 6.56007ZM7.5 0.00011965C8.42275 -0.0051262 9.33716 0.162175 10.1886 0.492021C11.04 0.821868 11.8109 1.30749 12.4553 1.91985C13.0997 2.53222 13.6043 3.25875 13.939 4.05603C14.2736 4.85331 14.4314 5.70496 14.403 6.56007C14.403 9.21605 12.102 12.3627 7.5 16C2.90088 12.36 0.599887 9.21338 0.59701 6.56007C0.568574 5.70496 0.726395 4.85331 1.06104 4.05603C1.39568 3.25875 1.90028 2.53222 2.54467 1.91985C3.18906 1.30749 3.96 0.821868 4.81142 0.492021C5.66284 0.162175 6.57726 -0.0051262 7.5 0.00011965Z'
			fill='#007DAF'
		/>
	</svg>
)

export default SvgPinSelect

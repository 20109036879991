const amaissp = {
	marca: 'amaissp',
	ouvidoria: 'https://maisservicos.amaissaude.com.br/ouvidoria?regional=sp',
	idMarca: '17'
}
const amaispr = {
	marca: 'amaispr',
	ouvidoria: 'https://maisservicos.amaissaude.com.br/ouvidoria?regional=pr',
	idMarca: '9'
}
const amaispe = {
	marca: 'amaispr',
	ouvidoria: 'https://maisservicos.amaissaude.com.br/ouvidoria?regional=pe',
	idMarca: '3'
}

const amaispi = {
	marca: 'amaispi',
	ouvidoria: 'https://maisservicos.amaissaude.com.br/ouvidoria?regional=pi',
	idMarca: '39'
}

const diagnoson = {
	marca: 'diagnoson',
	ouvidoria: 'https://maisservicos.diagnosonamais.com.br/ouvidoria',
	idMarca: '5'
}
const irn = {
	marca: 'irn',
	ouvidoria: 'https://maisservicos.institutoderadiologia.com.br/ouvidoria',
	idMarca: '26'
}
const labsamais = {
	marca: 'labs-amais',
	ouvidoria: 'https://maisservicos.labsamais.com.br/ouvidoria',
	idMarca: '22'
}

const getBrand = () => {
	const brand = process.env.GATSBY_COSMIC_BUCKET

	switch (brand) {
		case 'amaissp':
			return amaissp
		case 'amaispr':
			return amaispr
		case 'amaispe':
			return amaispe
		case 'amaispi':
			return amaispi
		case 'diagnoson':
			return diagnoson
		case 'irn':
			return irn
		case 'labs-amais':
			return labsamais
		default:
			return amaissp
	}
}

export default getBrand

import React, { useEffect, useState } from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import ModalCard from './modalCard'
import imgixUrlOptimizerAuto from '../../utils/imgixUrlOptimizerAuto'

import CovidIcon from '../../assets/img/covid-exams-icon.svg'
import VacinasIcon from '../../assets/img/vacinas-icon.svg'
//import ExamesGestanteIcon from '../../assets/img/exames-mulher-icon.svg'
import OutrosExamesIcon from '../../assets/img/outros-exames-icon.svg'
//import CheckInIcon from '../../assets/img/checkin-icon.svg'
// import OutrosExamesLaboratoriais from '../../assets/img/outros-exames-labor.svg'
//import EmCasaIcon from '../../assets/img/EmCasaIcon.svg'

import WhatsappIcon from '../../assets/img/whatsapp-icon.svg'
import TelefoneIcon from '../../assets/img/telefone-icon.svg'
import CloseWindow from '../../Icons/close-window.svg'
import AmaisAteVoce from '../../assets/img/amaisatevoce.svg'

const Voltar = styled.span`
	color: #009edb;
	text-decoration: none;
	font-weight: bold;
	justify-content: left;
	cursor: pointer;
	width: 100%;
	text-align: left;
	padding-top: 20px;
	margin-left: 12px;
`

const VoltarOrtopedic = styled.span`
	color: #009edb;
	text-decoration: none;
	font-weight: bold;
	justify-content: left;
	cursor: pointer;
	width: 100%;
	text-align: left;
	padding-top: 45px;
	margin-bottom: -15px;
	margin-left: 10px;
`

const VoltarCheckin = styled.span`
	color: #009edb;
	text-decoration: none;
	font-weight: bold;
	justify-content: left;
	cursor: pointer;
	width: 100%;
	text-align: left;
	padding-top: 45px;
	margin-bottom: -15px;
	margin-left: 22px;
`

const ImageContent = styled.div`
	margin: -20px 20px 0px 0px;
`

const CloseIcon = styled.img`
	cursor: pointer;
	display: block;
	float: right;
	margin: 0;
`

const WrapContent = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	text-align: center;
	///flex-wrap: wrap;
	margin-left: 12px;
	padding-bottom: 38px;
	min-height: 260px;

	@media (max-width: 424px) {
		// padding-top: 180px;
		margin-left: 10px;
	}
`

const Title = styled.p`
	/* margin: 0px 0px 10px; */
	font-size: 24px;
	font-weight: bold;
	line-height: 31px;
	text-align: center;
	//color: #0388c8;
	color: #302a38;
	width: 100%;

	@media (max-width: 320px) {
		margin: 0px;
	}
`

const CardContainer = styled.button`
	background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
`

const Subtitle = styled.span`
	font-size: 14px;
	line-height: 16px;
	text-align: center;
	color: #302a38;
	width: 100%;
`

const SubtitleOrtopedic = styled.span`
	font-size: 16px;
	font-weight: bold;
	margin-top: 5px;
	text-align: left;
	color: #00729e;
	width: 100%;
`

const Wrapper = styled.div`
	width: 220px;
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-column-gap: 10px;
	grid-row-gap: 10px;
	padding-top: 20px;

	/* @media (max-width: 320px) {
		padding: 10px 0px;
	} */
`

const TitleContent = styled.h1`
	font-weight: 500;
	font-size: 28px;
	line-height: 42px;
	color: #302a38;
`

const MaisServicos = styled.button`
	font-style: normal;
	font-weight: 800 !important;
	line-height: 20px;
	font-size: ${props => (props.fontsize ? props.fontsize : 14)}px;
	color: #0098b7;
	font-family: 'fs_lolabold' !important;
	width: 100%;
	text-decoration: none;
	margin-top: 25px;
	margin-left: 25px;
	margin-bottom: -15px;
	background: none;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
`

const LinkTo = styled.a`
	font-style: normal;
	font-weight: 800;
	line-height: 21px;
	font-size: ${props => (props.fontsize ? props.fontsize : 14)}px;
	color: #009edb;
	width: 100%;
	text-decoration: none;
	margin: 10px 0;
`

const LinkWithIcon = styled.a`
	display: flex;
	align-items: center;
	color: #009edb;
	width: 100%;
	text-decoration: none;
	margin: 10px 0;
	font-style: normal;
	font-weight: 700;
	font-size: 16px;
	line-height: 24px;

	img {
		display: inline-block;
		padding-right: 5px;
		float: left;
		width: 24px;
	}
`

const TextWithIcon = styled.span`
	display: flex;
	align-items: center;
	font-style: normal;
	font-weight: 700;
	font-size: 17px;
	line-height: 24px;
	/* identical to box height, or 150% */

	color: #464646;
	width: 100%;
	text-decoration: none;
	margin: 12px 0;

	img {
		display: inline-block;
		padding-right: 5px;
		float: left;
		width: 24px;
	}
`

const Content = styled.div`
	display: flex;
	text-align: left;
	font-family: fs_lolaregular;
	align-items: center;
	flex-wrap: wrap;
`

const SchedulesContent = styled.div`
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	color: #464646;
	width: 100%;
	line-height: 20px;
	margin-top: 8px;
	margin-bottom: 8px;
	padding-left: 8px;
	border-radius: 8px;
	background-color: #e5f3f1;

	ul {
		padding-left: 24px !important;
		margin-top: -8px;
	}
`

const TextContent = styled.span`
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	color: #464646;
	width: 100%;
	margin: 7px 0;

	strong {
		font-weight: 700;
	}
`
const Separator = styled.div`
	padding: 5px 0;
	width: 100%;
	border-bottom: 1px solid #d7d1ca;
`

const ContactWrapper = styled.div`
	display: flex;
	align-items: center;
	margin-left: 12px;
	text-align: left;
	padding: 10px 0px;
	width: 100%;

	@media (max-width: 380px) {
		padding: 8px 0px;
	}
`

const TitleWrapper = styled.div`
	a {
		color: #0088c8;
		font-weight: bold;
		text-decoration: none;
		display: inline-block;
	}

	strong,
	p {
		margin: 0px;
		font-size: 14px;
		font-weight: 900;
		line-height: 20px;
		text-align: left;
		color: #0088c8;
		display: block;
		padding: 0px 0px 5px;
		font-weight: bold;
	}
`

const IconWrapper = styled.div`
	width: 30px;
`

// const SubtitleWrapper = styled.div``

const ContactIcon = styled.img`
	margin-top: -5px;
	width: 20px;
`

const ContactInfo = styled.div`
	width: 90%;
`

function NewModal({ showSchedule, setShowSchedule, contatos, contatosAgendar }) {
	const [visible, setVisible] = useState('inicio')

	const backButton = <Voltar onClick={() => setVisible('inicio')}>❮ Voltar</Voltar>

	const backButtonOrtopedic = (
		<VoltarOrtopedic onClick={() => setVisible('inicio')}>❮&nbsp;&nbsp; Voltar</VoltarOrtopedic>
	)

	const backButtonCheckin = <VoltarCheckin onClick={() => setVisible('inicio')}>❮&nbsp;&nbsp; Voltar</VoltarCheckin>

	useEffect(() => {
		if (process.env.GATSBY_COSMIC_BUCKET === 'amaispi') {
			setVisible('outrosExames')
		} else {
			setVisible('inicio')
		}
	}, [showSchedule])

	return (
		<>
			{visible === 'inicio' && (
				<>
					<ImageContent>
						<CloseIcon
							onClick={() => {
								setVisible('inicio')
								setShowSchedule(false)
							}}
							alt='Fechar'
							src={CloseWindow}
						/>
					</ImageContent>
					<WrapContent>
						<Title id='title-schedule-modal'>Agendar</Title>
						<Subtitle>Exames, vacinas e mais</Subtitle>
						<Wrapper>
							{process.env.GATSBY_COSMIC_BUCKET === 'amaissp' && (
								<>
									<CardContainer onClick={() => window.open('https://agendamento.amaissaude.com.br/entrar', '_blank')}>
										<ModalCard image={AmaisAteVoce} title='a+ até você' subtitle='Sangue, urina e mais' />
									</CardContainer>
									<CardContainer onClick={() => window.open('https://agendamento.amaissaude.com.br/entrar', '_blank')}>
										<ModalCard image={CovidIcon} title='COVID-19 e Influenza' />
									</CardContainer>
									<CardContainer onClick={() => window.open('https://agendamento.amaissaude.com.br/entrar', '_blank')}>
										<ModalCard image={OutrosExamesIcon} title='Outros exames e vacinas' />
									</CardContainer>
									{/* 									
									<CardContainer onClick={() => window.open('https://agendamento.amaissaude.com.br', '_blank')}>
										<ModalCard image={ExamesGestanteIcon} title='Exames para gestantes' />
									</CardContainer> */}
								</>
							)}

							{process.env.GATSBY_COSMIC_BUCKET === 'amaispr' && (
								<>
									<Link to={'/noticias/exames-para-diagnostico-da-covid-19-estao-disponiveis-na-amais/'}>
										<CardContainer
										// onClick={() =>
										// window.location.replace('https://www.amaissaude.com.br/pr/noticias/exames-para-diagnostico-da-covid-19-estao-disponiveis-na-amais/')
										// window.open(
										// 'https://www.amaissaude.com.br/pr/noticias/exames-para-diagnostico-da-covid-19-estao-disponiveis-na-amais/'
										// 	)
										// }
										>
											<ModalCard image={CovidIcon} title='COVID-19' />
										</CardContainer>
									</Link>
									<Link to={'/noticias/vacinas-disponiveis-na-amais/'}>
										<CardContainer
										//onClick={	() =>
										//window.open('https://www.amaissaude.com.br/pr/noticias/vacinas-disponiveis-na-amais/')
										//window.location.replace('https://www.amaissaude.com.br/pr/noticias/vacinas-disponiveis-na-amais/')
										//}
										>
											<ModalCard image={VacinasIcon} title='Vacinas' />
										</CardContainer>
									</Link>
									<CardContainer onClick={() => setVisible('outrosExames')}>
										<ModalCard image={OutrosExamesIcon} title='Outros exames' />
									</CardContainer>
									{/*
									<CardContainer onClick={() => setVisible('checkin')}>
										<ModalCard image={CheckInIcon} title='Check-in' subtitle='Exames de sangue, urina e fezes' />
									</CardContainer>*/}
								</>
							)}

							{(process.env.GATSBY_COSMIC_BUCKET === 'amaispe' ||
								process.env.GATSBY_COSMIC_BUCKET === 'diagnoson' ||
								process.env.GATSBY_COSMIC_BUCKET === 'labs-amais') && (
								<>
									<CardContainer onClick={() => window.open('https://agende.grupofleury.com.br/', '_blank')}>
										<ModalCard image={CovidIcon} title='COVID-19 e Influenza' />
									</CardContainer>
									<CardContainer onClick={() => setVisible('outrosExames')}>
										<ModalCard image={OutrosExamesIcon} title='Outros exames' />
									</CardContainer>
								</>
							)}
						</Wrapper>
						{process.env.GATSBY_COSMIC_BUCKET === 'amaissp' && (
							<MaisServicos onClick={() => setVisible('ortopedia')}>Mais serviços</MaisServicos>
						)}
					</WrapContent>
				</>
			)}
			{visible === 'covid' && (
				<>
					<ImageContent>
						<CloseIcon onClick={() => setShowSchedule(false)} alt='Fechar' src={CloseWindow} />
					</ImageContent>
					<WrapContent>
						<Content>
							<TitleContent>COVID-19 e Influenza</TitleContent>
							<TextContent>Agende testes de COVID-19 e Influenza ou vacina de Influenza</TextContent>
							<LinkTo href='https://agende.grupofleury.com.br/' target='_blank'>
								❯ Agendar agora
							</LinkTo>
						</Content>
						{backButton}
					</WrapContent>
				</>
			)}

			{/* {visible === 'examesGestante' && (
				<>
					<ImageContent>
						<CloseIcon onClick={() => setShowSchedule(false)} alt='Fechar' src={CloseWindow} />
					</ImageContent>
					<WrapContent>
						<Content>
							<TitleContent>Exames para gestantes</TitleContent>
							<TextContent>Agende pelo telefone</TextContent>
							<LinkWithIcon href='tel:+551131398000' target='_blank'>
								<img src={TelefoneIcon} alt='Telefone icon' /> (11) 3139-8000
							</LinkWithIcon>
							<TextContent>Atendimento de segunda a sexta, das 7h às 20h e aos sábados, das 7h às 14h</TextContent>
						</Content>
						{backButton}
					</WrapContent>
				</>
			)} */}
			{visible === 'servicos' && (
				<>
					<ImageContent>
						<CloseIcon onClick={() => setShowSchedule(false)} alt='Fechar' src={CloseWindow} />
					</ImageContent>
					<WrapContent>
						<Content>
							<TitleContent>Mais serviços</TitleContent>
							<TextContent>
								Consulte agendamentos, reagende e cancele exames, veja resultados, instruções de preparo e mais
							</TextContent>
							<LinkWithIcon href='https://api.whatsapp.com/send?phone=551131398000' target='_blank'>
								<img src={WhatsappIcon} alt='whatsapp icon' /> (11) 3139-8000
							</LinkWithIcon>
							<TextContent>Atendimento de segunda a sexta, das 7h às 20h e aos sábados, das 7h às 14h</TextContent>
							<Separator />
							<TextWithIcon>a+ até você</TextWithIcon>
							<TextContent>Agende agora mesmo e faça seus exames sem sair de casa</TextContent>
							<LinkTo href='https://api.whatsapp.com/send?phone=551131398000' target='_blank'>
								❯ Agendar pelo WhatsApp
							</LinkTo>
							<LinkTo href='tel:+55113139-8000' target='_blank'>
								❯ Agendar pelo telefone
							</LinkTo>
						</Content>
						{backButton}
					</WrapContent>
				</>
			)}

			{visible === 'laboratoriais' && (
				<>
					<ImageContent>
						<CloseIcon onClick={() => setShowSchedule(false)} alt='Fechar' src={CloseWindow} />
					</ImageContent>
					<WrapContent>
						<Content>
							<TitleContent>Em casa</TitleContent>
							<TextContent>
								Consulte os valores, a cobertura e como se preparar. Agende para fazer em casa ou escolha uma unidade
							</TextContent>
							<LinkWithIcon href='https://agendamento.amaissaude.com.br/entrar' target='_blank'>
								❯ Agendar em casa
							</LinkWithIcon>
						</Content>
						{backButton}
					</WrapContent>
				</>
			)}

			{visible === 'outrosExames' && (
				<>
					<ImageContent>
						<CloseIcon onClick={() => setShowSchedule(false)} alt='Fechar' src={CloseWindow} />
					</ImageContent>
					<WrapContent>
						<Content>
							<TitleContent style={{ marginLeft: 20, marginTop: '-8px' }}>
								{contatosAgendar?.metadata.titulo}
							</TitleContent>
							<TextContent style={{ marginLeft: 20 }}>{contatosAgendar?.metadata.subtitulo}</TextContent>
							{contatosAgendar &&
								contatosAgendar.metadata?.canal_de_contato?.map((contato, i) => (
									<>
										<ContactWrapper key={i}>
											<IconWrapper style={{ marginLeft: 8 }}>
												<ContactIcon alt='Contato' src={imgixUrlOptimizerAuto(contato.icone.imgix_url)} />
											</IconWrapper>
											<ContactInfo>
												<TitleWrapper dangerouslySetInnerHTML={{ __html: contato.texto }}></TitleWrapper>
											</ContactInfo>
										</ContactWrapper>
										{contato.contato_subtitulo?.length > 1 && (
											<TextContent
												style={{ marginLeft: 20 }}
												dangerouslySetInnerHTML={{ __html: contato.contato_subtitulo?.replaceAll('strong', 'b') }}
											/>
										)}
									</>
								))}
							<SchedulesContent
								style={{ marginLeft: 20 }}
								dangerouslySetInnerHTML={{ __html: contatosAgendar?.metadata.horarios }}
							></SchedulesContent>
						</Content>
						{process.env.GATSBY_COSMIC_BUCKET !== 'amaispi' && backButton}
					</WrapContent>
				</>
			)}
			{visible === 'ortopedia' && (
				<>
					<ImageContent>
						<CloseIcon onClick={() => setShowSchedule(false)} alt='Fechar' src={CloseWindow} />
					</ImageContent>
					<WrapContent>
						<Content>
							<TitleContent style={{ marginTop: -2 }}>Mais serviços</TitleContent>
							<SubtitleOrtopedic>
								a+ Ortopedia
								<br />
								Unidade Tatuapé Henrique Dumont
							</SubtitleOrtopedic>
							<TextContent style={{ marginTop: 25, marginBottom: 10 }}>
								Consultas ortopédicas e atendimento de urgências. Agende pelo telefone
							</TextContent>
							<LinkWithIcon href='tel:+551140046750' target='_blank'>
								<img src={TelefoneIcon} alt='whatsapp icon' /> (11) 4004-6750
							</LinkWithIcon>
							<TextContent style={{ marginTop: 20, marginBottom: 10 }}>
								Atendimento de segunda a sexta, das 7h às 20h e aos sábados e feriados, das 8h às 14h
							</TextContent>
							<LinkTo href='https://amaisortopedia.com.br/' target='_blank'>
								Ver mais&nbsp;&nbsp;❯
							</LinkTo>
						</Content>
						{backButtonOrtopedic}
					</WrapContent>
				</>
			)}
			{visible === 'checkin' && (
				<>
					<ImageContent>
						<CloseIcon onClick={() => setShowSchedule(false)} alt='Fechar' src={CloseWindow} />
					</ImageContent>
					<WrapContent>
						<Content>
							<TitleContent style={{ marginTop: -2, marginLeft: 12 }}>Check-in</TitleContent>
							<TextContent style={{ marginTop: 15, marginLeft: 12, marginBottom: 10 }}>
								Faça o seu check-in digital para <b>exames de sangue, urina e fezes</b> e agilize seu atendimento no dia
								do exame.
							</TextContent>
							<TextContent style={{ marginTop: 15, marginLeft: 12, marginBottom: 20 }}>
								O check-in deve ser realizado pelo menos <b>24h antes do exame</b>.
							</TextContent>
							<LinkTo style={{ marginLeft: 12 }} href='https://checkinac.amaissaude.com.br/' target='_blank'>
								Fazer check-in
							</LinkTo>
						</Content>
						{backButtonCheckin}
					</WrapContent>
				</>
			)}
		</>
	)
}

export default NewModal

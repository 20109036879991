/* eslint-disable default-case */
/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect, useState } from 'react'
//import { getVisualizedOnboarding } from './onboardingControl'
import styled from 'styled-components'

import { Box, Button, Menu, MenuItem } from '@material-ui/core'
import { ChevronLeft, WhatsApp, Phone } from '@material-ui/icons'
import SvgComoPreparar from './ComoPreparar'
//import SvgVerAgendamentos from './VerAgendamentos'
import SvgExamesAgendados from './ExamesAgendados'

import { theme } from 'styled-tools'

import Dialog from '@material-ui/core/Dialog'
import CloseWindow from '../../Icons/close-window.svg'
import SvgReagendar from './Reagendar'
import SvgCancelar from './Cancelar'
import SvgCheckin from './Checkin'
import SvgIOS from './IOS'
import SvgAndroid from './Android'
import SvgExpandIcon from './ExpandIcon'

//import OnboardingMySchedules from '../OnboardingMySchedules/OnboardingMySchedules'

const ImageContent = styled.div`
	display: flex;
	justify-content: flex-end;
	align-items: flex-end;
	margin-left: 200px;
	margin-top: 10px;
`

const CloseIcon = styled.img`
	cursor: pointer;
	display: block;
	float: right;
	margin: 0;
	width: 14px;
`

const IconContainer = styled.div`
	padding: 16px;
	margin-top: -15px;
	svg {
		path {
			fill: #1ea2d9 !important;
		}
	}
	display: flex;
	justify-content: center;
`

const Title = styled.span`
	font-style: normal;
	font-weight: bold;
	font-size: 16px;
	line-height: 24px;
	text-align: center;
	color: #373f41;
	display: flex;
	justify-content: center;
	text-align: center;
	margin: 8px;
`

const TitleDownload = styled.span`
	font-style: normal;
	font-weight: 700;
	font-size: 20px;
	line-height: 22px;
	display: flex;
	justify-content: center;
	color: #1ea2d9 !important;
	text-align: center;
	margin: 8px;
	padding-bottom: 10px;
	color: ${theme('colors.button_agendar')};
	border-bottom: 5px solid ${theme('colors.button_agendar')};
`

const SvgOSContainer = styled.span`
	display: flex;
	flex-direction: column;
	justify-content: center;
	text-align: center;
	svg {
		margin: 8px;
		border: 0.5px solid #1ea2d9;
	}
`

const Text = styled.span`
	display: flex;
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 24px;
	text-align: center;
	color: #757575;
	margin: 8px;
	padding-bottom: 16px;
`

const CTAButton1 = styled(Button)`
	text-transform: initial !important;
	background-color: #1ea2d9 !important;
	color: #fff !important;
	width: 200px;
	padding: 1rem 2rem;
	border-radius: 30px !important;
	margin: 8px !important;
	height: 40px !important;
	font-family: 'fs_lolaregular' !important;
	font-style: normal !important;
	font-weight: 700 !important;
	font-size: 14px !important;
	line-height: 16px !important;
`

const CTAButton2 = styled(Button)`
	text-transform: initial !important;
	color: #1ea2d9 !important;
	width: 200px;
	padding: 1rem 2rem;
	border-radius: 30px !important;
	margin: 8px !important;
	margin-bottom: 16px !important;
	font-family: 'fs_lolaregular' !important;
	font-style: normal !important;
	font-weight: 700 !important;
	font-size: 14px !important;
	line-height: 16px !important;

	svg {
		padding-right: 6px;
	}
`

const CTAButton3 = styled(Button)`
	text-transform: initial !important;
	color: #1ea2d9 !important;
	width: 200px;
	padding: 1rem 2rem;
	border-radius: 30px !important;
	border: 1px solid #1ea2d9 !important;
	margin: 8px !important;
	height: 40px !important;
	font-family: 'fs_lolaregular' !important;
	font-style: normal !important;
	font-weight: 700 !important;
	font-size: 14px !important;
	line-height: 16px !important;
`

const BackButton = styled(Button)`
	text-transform: initial !important;
	color: #1ea2d9 !important;
	font-family: 'fs_lolaregular' !important;
	font-style: normal !important;
	font-weight: 700 !important;
	font-size: 14px !important;
	line-height: 24px !important;
	margin: 16px;

	svg {
		zoom: 0.8;
		padding-right: 6px;
	}
`

const MUIButton = styled(Button)`
	text-transform: initial !important;
	margin-right: 12px !important;
	color: #343838 !important;
	font-family: 'fs_lolaregular' !important;
	font-size: 16px !important;
	line-height: 26px !important;
	align-items: center;
	svg {
		color: #009edb;
	}
`

const MenuItemStyled = styled(MenuItem)`
	font-family: 'fs_lolaregular' !important;
`

const Separator = styled.div`
	color: ${props => props.theme.colors.primary};
	background-color: ${props => props.theme.colors.primary};
	height: 2px;
	width: 70%;
	border: 0;
	margin-left: 15%;
	margin-top: 8px;
`

function SimpleDialog(props) {
	const { onClose, open, optionSelected } = props

	const [openStoresPage, setOpenStoresPage] = useState(false)
	const [downloadDesktop, setDownloadDesktop] = useState(false)

	const handleClose = () => {
		onClose()
	}

	useEffect(() => {
		const IS_IPAD = navigator.userAgent.match(/iPad/i) != null,
			IS_IPHONE =
				!IS_IPAD && (navigator.userAgent.match(/iPhone/i) != null || navigator.userAgent.match(/iPod/i) != null),
			IS_IOS = IS_IPAD || IS_IPHONE,
			IS_ANDROID = !IS_IOS && navigator.userAgent.match(/android/i) != null,
			IS_MOBILE = IS_IOS || IS_ANDROID

		if (IS_MOBILE) {
			setDownloadDesktop(false)
		} else {
			setDownloadDesktop(true)
		}
	}, [])

	useEffect(() => {
		setOpenStoresPage(false)
	}, [open])

	return (
		<Dialog onClose={handleClose} aria-labelledby='simple-dialog-title' open={open}>
			{!openStoresPage ? (
				<Box display='flex' gap={3} flexDirection='column' flexWrap='wrap' width={240} alignItems='center' padding={1}>
					<ImageContent>
						<CloseIcon
							onClick={() => {
								handleClose()
							}}
							alt='Fechar'
							src={CloseWindow}
						/>
					</ImageContent>
					<IconContainer>{optionSelected?.icon}</IconContainer>
					<Title>{optionSelected?.title}</Title>
					<Text>{optionSelected?.text}</Text>

					{optionSelected?.action3Link ? (
						<>
							<CTAButton1
								onClick={() => window.open(optionSelected?.action3Link, '_blank')}
								variant='contained'
								size='large'
							>
								{optionSelected?.action3Text}
							</CTAButton1>
							{optionSelected?.action1Link && (
								<CTAButton3
									onClick={() => {
										if (downloadDesktop) {
											setOpenStoresPage(true)
										} else {
											window.open(optionSelected?.action1Link)
										}
									}}
									variant='outlined'
									size='large'
								>
									{optionSelected?.action1Text}
								</CTAButton3>
							)}
						</>
					) : (
						<CTAButton1
							onClick={() => {
								if (downloadDesktop) {
									setOpenStoresPage(true)
								} else {
									window.open(optionSelected?.action1Link)
								}
							}}
							variant='contained'
							size='large'
						>
							{optionSelected?.action1Text}
						</CTAButton1>
					)}
					{optionSelected?.action2Text && (
						<CTAButton2 size='large' onClick={() => window.open(optionSelected?.action2Link, '_blank')}>
							{optionSelected?.action2Text}
						</CTAButton2>
					)}
					<Box width='100%' display='flex' justifyContent='center'>
						<BackButton
							onClick={() => {
								handleClose()
							}}
						>
							Voltar
						</BackButton>
					</Box>
				</Box>
			) : (
				<Box display='flex' gap={3} flexDirection='column' flexWrap='wrap' width={220} alignItems='center' padding={3}>
					<TitleDownload>Baixe o aplicativo</TitleDownload>
					<SvgOSContainer>
						<a href={optionSelected.linkIos} target='_blank'>
							<SvgIOS />
						</a>
						<a href={optionSelected.linkAndroid} target='_blank'>
							<SvgAndroid />
						</a>
					</SvgOSContainer>
					<Box width='100%' display='flex' justifyContent='flex-start'>
						<BackButton onClick={() => setOpenStoresPage(false)}>
							<ChevronLeft /> Voltar{' '}
						</BackButton>
					</Box>
				</Box>
			)}
		</Dialog>
	)
}

function MySchedules() {
	const whatsappLinksAmaisSP = {
		whatsExamesAgendados:
			'https://api.whatsapp.com/send?phone=551131398000&text=Ol%C3%A1!%20Quero%20ver%20meus%20exames%20agendados',
		whatsComoMePreparar:
			'https://api.whatsapp.com/send?phone=551131398000&text=Ol%C3%A1!%20Quero%20saber%20como%20me%20preparar%20para%20os%20meus%20exames',
		whatsReagendarExames:
			'https://api.whatsapp.com/send?phone=551131398000&text=Ol%C3%A1!%20Quero%20reagendar%20um%20exame',
		whatsCancelarExames:
			'https://api.whatsapp.com/send?phone=551131398000&text=Ol%C3%A1!%20Quero%20cancelar%20um%20exame'
	}

	const whatsappLinksAmaisPE = {
		whatsExamesAgendados:
			'https://api.whatsapp.com/send?phone=558140043050&text=Ol%C3%A1!%20Quero%20ver%20meus%20exames%20agendados',
		whatsComoMePreparar:
			'https://api.whatsapp.com/send?phone=558140043050&text=Ol%C3%A1!%20Quero%20saber%20como%20me%20preparar%20para%20os%20meus%20exames',
		whatsReagendarExames:
			'https://api.whatsapp.com/send?phone=558140043050&text=Ol%C3%A1!%20Quero%20reagendar%20um%20exame',
		whatsCancelarExames:
			'https://api.whatsapp.com/send?phone=558140043050&text=Ol%C3%A1!%20Quero%20cancelar%20um%20exame'
	}

	const whatsappLinksAmaisPR = {
		whatsExamesAgendados:
			'https://api.whatsapp.com/send?phone=554140208000&text=Ol%C3%A1!%20Quero%20ver%20meus%20exames%20agendados',
		whatsComoMePreparar:
			'https://api.whatsapp.com/send?phone=554140208000&text=Ol%C3%A1!%20Quero%20saber%20como%20me%20preparar%20para%20os%20meus%20exames',
		whatsReagendarExames:
			'https://api.whatsapp.com/send?phone=554140208000&text=Ol%C3%A1!%20Quero%20reagendar%20um%20exame',
		whatsCancelarExames:
			'https://api.whatsapp.com/send?phone=554140208000&text=Ol%C3%A1!%20Quero%20cancelar%20um%20exame'
	}

	const whatsappLinksIRN = {
		whatsExamesAgendados:
			'https://api.whatsapp.com/send?phone=558440043220&text=Ol%C3%A1!%20Quero%20ver%20meus%20exames%20agendados',
		whatsComoMePreparar:
			'https://api.whatsapp.com/send?phone=558440043220&text=Ol%C3%A1!%20Quero%20saber%20como%20me%20preparar%20para%20os%20meus%20exames',
		whatsReagendarExames:
			'https://api.whatsapp.com/send?phone=558440043220&text=Ol%C3%A1!%20Quero%20reagendar%20um%20exame',
		whatsCancelarExames:
			'https://api.whatsapp.com/send?phone=558440043220&text=Ol%C3%A1!%20Quero%20cancelar%20um%20exame'
	}

	const whatsappLinksDiagnoson = {
		whatsExamesAgendados:
			'https://api.whatsapp.com/send?phone=557140043010&text=Ol%C3%A1!%20Quero%20ver%20meus%20exames%20agendados',
		whatsComoMePreparar:
			'https://api.whatsapp.com/send?phone=557140043010&text=Ol%C3%A1!%20Quero%20saber%20como%20me%20preparar%20para%20os%20meus%20exames',
		whatsReagendarExames:
			'https://api.whatsapp.com/send?phone=557140043010&text=Ol%C3%A1!%20Quero%20reagendar%20um%20exame',
		whatsCancelarExames:
			'https://api.whatsapp.com/send?phone=557140043010&text=Ol%C3%A1!%20Quero%20cancelar%20um%20exame'
	}

	const whatsappLinksLabs = {
		whatsExamesAgendados: '',
		whatsComoMePreparar: '',
		whatsReagendarExames: '',
		whatsCancelarExames: ''
	}

	const brandLinks = [
		{
			brand: 'amaissp',
			directLink: [
				'https://agendamento.amaissaude.com.br/agendamentos',
				'https://agendamento.amaissaude.com.br/agendamentos',
				'https://agendamento.amaissaude.com.br/agendamentos',
				'https://agendamento.amaissaude.com.br/agendamentos'
			],
			action3Link: '',
			action3Text: '',
			link: 'https://grupofleury.page.link/amais',
			link3: 'https://agendamento.amaissaude.com.br',
			linkStoreIos: 'https://apps.apple.com/us/app/a/id1468346417',
			linkStoreAndroid: 'https://play.google.com/store/apps/details?id=br.com.grupofleury.amais&hl=pt_BR&gl=US',
			...whatsappLinksAmaisSP
		},
		{
			brand: 'amaispe',
			directLink: [
				'https://maisservicos.amaissaude.com.br/entrar?regional=pe',
				'https://maisservicos.amaissaude.com.br/entrar?regional=pe',
				'https://api.whatsapp.com/send?phone=558140043050&text=Ol%C3%A1!%20Quero%20reagendar%20um%20exame',
				'https://maisservicos.amaissaude.com.br/entrar?regional=pe'
			],
			action3Link: '',
			action3Text: '',
			link: 'https://grupofleury.page.link/amais',
			linkStoreIos: 'https://apps.apple.com/us/app/a/id1468346417',
			linkStoreAndroid: 'https://play.google.com/store/apps/details?id=br.com.grupofleury.amais&hl=pt_BR&gl=US',
			...whatsappLinksAmaisPE
		},
		{
			brand: 'amaispr',
			directLink: [
				'https://maisservicos.amaissaude.com.br/entrar?regional=pr',
				'https://maisservicos.amaissaude.com.br/entrar?regional=pr',
				'https://api.whatsapp.com/send?phone=554140208000&text=Ol%C3%A1!%20Quero%20reagendar%20um%20exame',
				'https://maisservicos.amaissaude.com.br/entrar?regional=pr'
			],
			action3Link: '',
			action3Text: '',
			link: 'https://grupofleury.page.link/amais',
			linkStoreIos: 'https://apps.apple.com/us/app/a/id1468346417',
			linkStoreAndroid: 'https://play.google.com/store/apps/details?id=br.com.grupofleury.amais&hl=pt_BR&gl=US',
			...whatsappLinksAmaisPR
		},
		{
			brand: 'irn',
			directLink: [
				'https://maisservicos.institutoderadiologia.com.br',
				'https://maisservicos.institutoderadiologia.com.br',
				'https://api.whatsapp.com/send?phone=558440043220&text=Ol%C3%A1!%20Quero%20reagendar%20um%20exame',
				'https://maisservicos.institutoderadiologia.com.br'
			],
			action3Link: '',
			action3Text: '',
			link: 'https://grupofleury.page.link/irn',
			linkStoreIos: 'https://apps.apple.com/us/app/instituto-de-radiologia/id1462663036?l=pt&ls=1',
			linkStoreAndroid: 'https://play.google.com/store/apps/details?id=br.com.grupofleury.irn&hl=pt_BR',
			...whatsappLinksIRN
		},
		{
			brand: 'diagnoson',
			directLink: [
				'https://maisservicos.diagnosonamais.com.br',
				'https://maisservicos.diagnosonamais.com.br',
				'https://api.whatsapp.com/send?phone=557140043010&text=Ol%C3%A1!%20Quero%20reagendar%20um%20exame',
				'https://maisservicos.diagnosonamais.com.br'
			],
			action3Link: '',
			action3Text: '',
			link: 'https://grupofleury.page.link/diagnoson',
			linkStoreIos: 'https://apps.apple.com/br/app/diagnoson-a-clientes/id1271386063?l=en',
			linkStoreAndroid: 'https://play.google.com/store/apps/details?id=br.com.grupofleury.diagnoson',
			...whatsappLinksDiagnoson
		},
		{
			brand: 'labs-amais',
			directLink: [
				'https://agendamento.labsamais.com.br/agendamentos',
				'https://agendamento.labsamais.com.br/agendamentos',
				'https://agendamento.labsamais.com.br/agendamentos',
				'https://agendamento.labsamais.com.br/agendamentos'
			],
			action3Link: 'tel:2125383600',
			action3Text: '(21) 2538-3600',
			link: 'https://grupofleury.page.link/labs',
			linkStoreIos: 'https://apps.apple.com/us/app/labs-a/id1444747907?l=pt&ls=1',
			linkStoreAndroid: 'https://play.google.com/store/apps/details?id=br.com.grupofleury.labs&hl=pt_BR',
			...whatsappLinksLabs
		}
	]
	const [anchorEl, setAnchorEl] = useState(null)

	const [optionSelected, setOptionSelected] = useState(0)

	const [open, setOpen] = useState(false)

	//const visualizedOnboarding = getVisualizedOnboarding()

	const brandOptions = brandLinks.find(x => x.brand === process.env.GATSBY_COSMIC_BUCKET)

	const options = [
		{
			icon: <SvgComoPreparar />,
			title: 'Como me preparar',
			text: 'Por onde quer ver as instruções para seus exames?',
			action1Text: 'Ver no app',
			action1Link: brandOptions?.link,
			linkIos: brandOptions?.linkStoreIos,
			linkAndroid: brandOptions?.linkStoreAndroid,
			action2Text: (
				<>
					<WhatsApp /> Ver no WhatsApp
				</>
			),
			action2Link: brandOptions?.whatsComoMePreparar
		},
		{
			icon: <SvgExamesAgendados />,
			title: 'Exames agendados',
			text: 'Por onde quer consultar seus agendamentos?',
			action1Text: 'Consultar no app',
			action1Link: brandOptions?.link,
			linkIos: brandOptions?.linkStoreIos,
			linkAndroid: brandOptions?.linkStoreAndroid,
			action2Text: (
				<>
					<WhatsApp /> Consultar no WhatsApp
				</>
			),
			action2Link: brandOptions?.whatsExamesAgendados,
			action3Text: brandOptions?.link3 ? 'Consultar no site' : null,
			action3Link: brandOptions?.link3 ? brandOptions.link3 : null
		},
		{
			icon: <SvgReagendar />,
			title: 'Reagendar',
			text: 'Por onde quer reagendar?',
			action1Text: '',
			action1Link: '',
			linkIos: '',
			linkAndroid: '',
			action2Text: '',
			action2Link: '',
			action3Text: (
				<>
					<Phone fontSize='small' />
					&nbsp;{brandOptions?.action3Text}
				</>
			),
			action3Link: brandOptions?.action3Link
		},
		{
			icon: <SvgCancelar />,
			title: 'Cancelar',
			text: 'Por onde quer cancelar?',
			action1Text: '',
			action1Link: '',
			linkIos: '',
			linkAndroid: '',
			action2Text: '',
			action2Link: '',
			action3Text: (
				<>
					<Phone fontSize='small' />
					&nbsp;{brandOptions?.action3Text}
				</>
			),
			action3Link: brandOptions?.action3Link
		},
		{
			icon: <SvgCheckin />,
			title: 'Check-in',
			text: (
				<p>
					Faça o seu check-in digital para <b>exames de sangue, urina e fezes</b> e agilize seu atendimento no dia do
					exame.
					<br />O check-in deve ser realizado <b>pelo menos 24h antes do exame</b>
				</p>
			),

			action3Text: 'Fazer check-in',
			action3Link: 'https://checkinac.amaissaude.com.br'
		}
	]

	const handleSelect = option => {
		handleClose()
		if (brandOptions?.directLink[option] !== '' && option !== 4) {
			if (
				brandOptions?.directLink[option].substring(0, 20) === 'https://api.whatsapp' ||
				brandOptions?.directLink[option].substring(0, 4) === 'tel:'
			) {
				window.open(brandOptions?.directLink[option], '_blank')
			} else {
				window.location.href = brandOptions?.directLink[option]
			}
		} else {
			setOptionSelected(option)
			setOpen(true)
		}
	}

	const handleClick = event => {
		setAnchorEl(event.currentTarget)
	}

	const handleClose = () => {
		setAnchorEl(null)
	}

	return (
		<>
			<SimpleDialog open={open} onClose={() => setOpen(false)} optionSelected={options[optionSelected]} />
			<MUIButton
				aria-controls='simple-menu'
				aria-haspopup='true'
				onClick={handleClick}
				onMouseLeave={() => setOpen(false)}
			>
				<b>Agendamentos</b>&nbsp;&nbsp;
				<SvgExpandIcon />
			</MUIButton>

			<Menu
				anchorOrigin={{
					vertical: 'bottom'
					// horizontal: 'center'
				}}
				style={{ marginTop: 43 }}
				id='simple-menu'
				anchorEl={anchorEl}
				keepMounted
				open={Boolean(anchorEl)}
				onClose={handleClose}
				onMouseLeave={handleClose}
			>
				<MenuItemStyled style={{ justifyContent: 'center' }} onClick={() => handleSelect(1)}>
					Exames agendados
				</MenuItemStyled>
				<MenuItemStyled style={{ justifyContent: 'center' }} onClick={() => handleSelect(0)}>
					Como me preparar
				</MenuItemStyled>
				<MenuItemStyled style={{ justifyContent: 'center' }} onClick={() => handleSelect(2)}>
					Reagendar exame
				</MenuItemStyled>
				<MenuItemStyled style={{ justifyContent: 'center' }} onClick={() => handleSelect(3)}>
					Cancelar exame
				</MenuItemStyled>
				{process.env.GATSBY_COSMIC_BUCKET.toLowerCase() === 'amaispr' && (
					<>
						<Separator />
						<MenuItemStyled style={{ justifyContent: 'center' }} onClick={() => handleSelect(4)}>
							Check-in
						</MenuItemStyled>
					</>
				)}
			</Menu>
			{/* {!visualizedOnboarding && <OnboardingMySchedules />} */}
		</>
	)
}

export default MySchedules
